import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import style from '../assets/style/pages/contact.module.scss';

export default ({ data }) => {

  const map = data.map.childImageSharp.fluid;

  return (
    <Layout>
      <SEO title="Kontakt" />

      <section className={style.contactSection}>
        <div className="container">
          <div className={`row ${style.contactSection__row}`}>

            <div className="col-12 offset-lg-1 col-lg-4 offset-xxl-2 col-xxl-3">
              <h1>Kontakt</h1>
              <address>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" /></svg>
                  ul. Łagiewnicka 39, <br /> 30-417 Kraków
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z"/></svg>
                  <a href="tel:12-264-30-63">12-264-30-63</a>
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z"/></svg>
                  <a href="mailto:biuro@bpd-krakow.pl">biuro@bpd-krakow.pl</a>
                </p>
              </address>
            </div>

            <div className="col-12 col-lg-7 col-xxl-6 col-xxxl-6">
              <div className={style.mapWrapper}>
                <Img fluid={map} className={style.map} alt="Mapa" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    map: file(relativePath: { eq: "assets/images/map.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
